import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO title="Thank you!" description="Thank you!" />
      <div className="page-content py-6 returns-page pb-280">
        <div className="container">
          <div className="text-capitalize text-center">
            <h2 className="section-title font-italic">
              THANK <span className="text-brown">YOU!</span>
            </h2>
          </div>
          <div className="row justify-content-center my-4 text-center">
            <div className="col-md-8">
              <p className="mb-4">
                We have received your message and will get back to you shortly.
              </p>
              <Link to="/" className="contact-btn btn btn-primary">
                BACK TO HOME
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ThankYouPage
